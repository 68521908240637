import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app/App';
import { getConfig } from './utils/config';
import { initLogRocket } from './utils/logRocket';

const { environment } = getConfig();

if (environment !== 'local') {
  initLogRocket();
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
