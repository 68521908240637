import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { usePost } from 'hooks/usePost';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AuthUser } from 'types/user';
import { useNotificationMessages } from 'utils/notifications';

interface AddStaffModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface AddStaffForm {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  role: string;
}

interface FormRoles {
  label: string;
  description: string;
  value: string;
}

export const AddStaffModal = ({ isOpen, onClose }: AddStaffModalProps) => {
  const theme = useTheme();
  const { user } = useAuth0<AuthUser>();
  const createUser = usePost(`/partner-api/agencies/${user?.userData.agencyId}/users`);
  const { showErrorMessage, showSuccessMessage } = useNotificationMessages();
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddStaffForm>({
    mode: 'onTouched',
    resetOptions: {
      keepDirtyValues: false, // user-interacted input will be retained
    },
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobileNumber: '',
      role: '',
    },
  });

  const roleOptionsGross: FormRoles[] = [
    {
      label: 'Admin',
      description: '',
      value: 'agent-agency-admin',
    },
    {
      label: 'Staff',
      description: '(can view pricing & commission)',
      value: 'agent',
    },
    {
      label: 'Staff',
      description: '(can only view pricing)',
      value: 'agent-no-commission',
    },
    {
      label: 'Staff',
      description: '(cannot view pricing or commission)',
      value: 'agent-no-pricing',
    },
  ];

  const roleOptionsNet: FormRoles[] = [
    {
      label: 'Admin',
      description: '',
      value: 'agent-agency-admin',
    },
    {
      label: 'Staff',
      description: '(can view pricing & commission)',
      value: 'agent-no-commission',
    },
    {
      label: 'Staff',
      description: '(cannot view pricing or commission)',
      value: 'agent-no-pricing',
    },
  ];

  const roleOptions = user?.userData?.pricingMode === 'gross' ? roleOptionsGross : roleOptionsNet;

  const closeAndResetForm = () => {
    reset();
    onClose();
  };

  const onSubmit = handleSubmit(async (data: AddStaffForm) => {
    try {
      setIsLoading(true);
      await createUser(data);
      showSuccessMessage(`Staff user ${data.firstName} ${data.lastName} created`);
      setIsLoading(false);
      closeAndResetForm();
    } catch (e) {
      showErrorMessage((e as Error).message);
      setIsLoading(false);
    }
  });

  return (
    <>
      {isOpen && <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />}
      <Modal open={isOpen} aria-labelledby="add-staff-modal-title" aria-describedby="add-staff-modal-description">
        <Box sx={theme.mixins.modalStyle}>
          <Stack flex={1}>
            <Stack direction="row" alignItems="center">
              <Avatar sx={{ backgroundColor: 'primary.light', mr: 2 }}>
                <PersonAddIcon sx={{ color: 'primary.dark' }} />
              </Avatar>
              <Box flex={1} />
              <Tooltip title="Discard">
                <span>
                  <IconButton aria-label="discard" onClick={() => closeAndResetForm()}>
                    <CloseIcon color="secondary" />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            <Typography id="add-staff-modal-title" variant="h6" component="h2" my={2}>
              Add staff
            </Typography>

            <Box minHeight={0} flex={1}>
              <Stack spacing={1}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: 'First name is required',
                    maxLength: {
                      value: 200,
                      message: 'Name is too long',
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth required>
                      <FormLabel id="add-staff-first-name-label">First name</FormLabel>
                      <TextField
                        required
                        fullWidth
                        aria-labelledby="add-staff-first-name-label"
                        value={value}
                        size="small"
                        onChange={onChange}
                        error={Boolean(errors.firstName)}
                      />
                      {errors.firstName ? <Typography color="error">{errors.firstName.message}</Typography> : <br />}
                    </FormControl>
                  )}
                />
                <Controller
                  name="lastName"
                  control={control}
                  rules={{
                    required: 'Last name is required',
                    maxLength: {
                      value: 200,
                      message: 'Name is too long',
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth required>
                      <FormLabel id="add-staff-last-name-label">Last name</FormLabel>
                      <TextField
                        required
                        fullWidth
                        aria-labelledby="add-staff-last-name-label"
                        value={value}
                        size="small"
                        onChange={onChange}
                        error={Boolean(errors.lastName)}
                      />
                      {errors.lastName ? <Typography color="error">{errors.lastName.message}</Typography> : <br />}
                    </FormControl>
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Invalid email',
                    },
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth required>
                      <FormLabel id="add-staff-email-label">Email</FormLabel>
                      <TextField
                        required
                        fullWidth
                        aria-labelledby="add-staff-email-label"
                        value={value}
                        size="small"
                        onChange={onChange}
                        error={Boolean(errors.lastName)}
                      />
                      {errors.email ? <Typography color="error">{errors.email.message}</Typography> : <br />}
                    </FormControl>
                  )}
                />
                <Controller
                  name="mobileNumber"
                  control={control}
                  rules={{
                    required: 'Mobile number is required',
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth required>
                      <FormLabel id="add-staff-mobile-label">Mobile number</FormLabel>
                      <TextField
                        required
                        fullWidth
                        aria-labelledby="add-staff-mobile-label"
                        value={value}
                        size="small"
                        onChange={onChange}
                        error={Boolean(errors.lastName)}
                      />
                      {errors.mobileNumber ? <Typography color="error">{errors.mobileNumber.message}</Typography> : <br />}
                    </FormControl>
                  )}
                />
                <Controller
                  name="role"
                  control={control}
                  rules={{
                    required: 'Role is required',
                  }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl fullWidth size="small" required>
                      <FormLabel id="add-staff-role-label">Role</FormLabel>
                      <Select
                        labelId="add-staff-role-select-label"
                        id="add-staff-role-select"
                        label="Select..."
                        value={value}
                        onChange={(event) => onChange(event.target.value as string)}
                      >
                        {roleOptions.map((role) => (
                          <MenuItem key={role.value} value={role.value}>
                            <Typography sx={{ mr: 1 }}>{role.label}</Typography>
                            <Typography sx={{ ...theme.mixins.secondaryText }}>{role.description}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.role ? <Typography color="error">{errors.role.message}</Typography> : <br />}
                    </FormControl>
                  )}
                />
              </Stack>
            </Box>
            <FormControl fullWidth>
              <Stack direction="row" spacing={1} mt={3}>
                <Button fullWidth variant="outlined" onClick={() => closeAndResetForm()} color="secondary">
                  Cancel
                </Button>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  disabled={Boolean(Object.keys(errors).length !== 0)}
                  onClick={onSubmit}
                  loading={isLoading}
                  color="primary"
                >
                  Add Staff
                </LoadingButton>
              </Stack>
            </FormControl>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
