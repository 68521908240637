import { getConfig } from 'utils/config';
import { useAuth0 } from '@auth0/auth0-react';
import { getErrorMessage } from 'utils/errors';
import { deepMerge } from 'utils/deepMerge';
import { loginConfig } from 'app/auth/login-config';

interface UseGetOptions {
  useApiUrl?: boolean;
  headers?: HeadersInit;
}

export const useGet = <T>(url: string, options: UseGetOptions = {}) => {
  const { useApiUrl = true, headers = {} } = options;
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiUrl } = getConfig();
  const fetchUrl = useApiUrl ? `${apiUrl}${url}` : url;

  return async (params: URLSearchParams = new URLSearchParams()): Promise<T> => {
    try {
      const token = await getAccessTokenSilently();
      const config = deepMerge(
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
          method: 'GET',
        },
        { headers },
      );

      return fetch(`${fetchUrl}?${params.toString()}`, config).then(async (resp) => {
        let body;
        if (resp.headers.get('content-type') === 'application/json') {
          body = await resp.json();
        } else {
          body = await resp.text();
        }

        if (resp.status !== 200 && resp.status !== 204) {
          throw new Error(getErrorMessage(body));
        }

        return body;
      });
    } catch {
      await loginWithRedirect(loginConfig(window.location.pathname));
      throw new Error('Not logged in. Redirecting...');
    }
  };
};
