import { Loader } from 'components/Loader';
import { theme } from 'app/theme';
import { Box } from '@mui/material';

export const CallbackLanding = () => {
  const appBarHeight = 7;

  return (
    <Box component="main" display="flex" flexDirection="column" minHeight="100vh" height="100vh" overflow={'auto'}>
      <Box flex={1} mt={appBarHeight} height={`calc(100% - ${theme.spacing(appBarHeight)})`}>
        <Loader />
      </Box>
    </Box>
  );
};
