import { RedirectLoginOptions } from '@auth0/auth0-react/dist/auth0-context';
import { getConfig } from 'utils/config';

export const loginConfig = (returnTo: string): RedirectLoginOptions => {
  const { auth0Audience, auth0Organisation } = getConfig();

  return {
    appState: {
      returnTo,
    },
    authorizationParams: {
      audience: auth0Audience,
      scope: 'openid profile email offline_access read:current_user',
      organization: auth0Organisation,
    },
  };
};
