import { AppBar, Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import Logo from 'assets/images/logo-educatius-footer.svg';

export const EducatiusFooter = () => {
  const theme = useTheme();

  const linkSx = {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
  };
  const year = new Date().getFullYear();

  return (
    <AppBar
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        px: '32px',
        py: '16px',
        borderRadius: 0,
        backgroundColor: '#fff',
        width: '100%',
        borderTop: `solid 1px ${grey[200]}`,
        top: 'auto',
        bottom: 0,
        zIndex: 1250,
      }}
      component="footer"
      position="relative"
      elevation={0}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={Logo} alt="Educatius" height="26px" />
      </Box>
      <Box flex={1} />

      <Stack direction="column" alignItems="end">
        <Link href="//www.educatius.org/privacy-policy" target="_blank" rel="noopener noreferrer" sx={{ ...linkSx, ml: 2 }}>
          <Typography variant="body2" fontWeight="500" color={theme.palette.primary.main}>
            Privacy Policy
          </Typography>
        </Link>
        <Typography variant="body2" sx={{ mt: 1, ml: 2, color: grey[800], fontWeight: 500 }}>
          &copy;{year} Educatius Ltd. All rights reserved
        </Typography>
      </Stack>
    </AppBar>
  );
};
