import { useSnackbar } from 'notistack';

export const useNotificationMessages = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessMessage = (message: string) => enqueueSnackbar(message, { variant: 'success', key: message, preventDuplicate: true });
  const showErrorMessage = (message: string) => {
    enqueueSnackbar(message, { variant: 'error', key: message, preventDuplicate: true });
    console.error(message);
  };

  return { showSuccessMessage, showErrorMessage };
};
