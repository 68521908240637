import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Popover, SxProps, Theme, useTheme } from '@mui/material';
import { ReactNode, useState } from 'react';
import { AuthUser } from 'types/user';
import { getConfig } from 'utils/config';

interface UserMenuIconButtonProps {
  enableProfileLink?: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  activeSx?: SxProps<Theme>;
}

export const UserMenuIconButton = ({ enableProfileLink, children, sx, activeSx }: UserMenuIconButtonProps) => {
  const { user, logout } = useAuth0<AuthUser>();
  const theme = useTheme();
  const { enrolmentsUrl, marketplaceUrl } = getConfig();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (!user) return <></>;

  const mainSchoolSlug = user!.userData.schoolSlugs[0] ?? null;
  const profilePageUrl =
    user!.userData.roles.includes('agent') || mainSchoolSlug === null ? `${enrolmentsUrl}/profile` : `${enrolmentsUrl}/${mainSchoolSlug}/profile`;

  const onProfileLinkClick = () => {
    if (enableProfileLink) window.open(profilePageUrl, '_blank');
  };

  const currentSx = { ...sx, ...(anchorEl && activeSx) };

  return (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} sx={currentSx} data-intercom-target="account menu">
        {children}
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <List>
          <ListItem onClick={onProfileLinkClick} sx={{ cursor: enableProfileLink ? 'pointer' : 'inherit' }}>
            <ListItemAvatar>
              <Avatar sx={{ background: theme.palette.primary.main }}>
                {user!.userData.firstName[0]}
                {user!.userData.lastName[0]}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${user!.userData.firstName} ${user!.userData.lastName}`} secondary={user!.email} />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText primary="" />
            <Button
              variant="outlined"
              onClick={() => {
                setAnchorEl(null);
                logout({ logoutParams: { returnTo: marketplaceUrl } });
              }}
            >
              Logout
            </Button>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};
