import { Branding } from 'branding/Branding';

import { EnrollerHeader } from './EnrollerHeader';
import { EnrollerFooter } from './EnrollerFooter';

const primaryMain = '#3EAD47';

export const enrollerBranding: Branding = {
  header: <EnrollerHeader />,
  headerHeight: 7,
  footer: <EnrollerFooter />,
  fontFamily: 'Inter, sans-serif',
  paletteOptions: {
    primary: {
      main: primaryMain,
      light: '#C5E6C8',
      dark: '#039855',
      contrastText: '#ffffff',
    },
    titleGradientColor1: primaryMain,
    titleGradientColor2: '#335f33',
  },
};
