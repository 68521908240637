import { useAuth0 } from '@auth0/auth0-react';
import { loginConfig } from 'app/auth/login-config';
import { getConfig } from 'utils/config';
import { deepMerge } from 'utils/deepMerge';
import { getErrorMessage } from 'utils/errors';

interface UsePostOptions {
  useApiUrl?: boolean;
  isMultiPartForm?: boolean;
  headers?: HeadersInit;
}

export const usePost = <T>(url: string, options: UsePostOptions = {}) => {
  const { useApiUrl = true, isMultiPartForm = false, headers = {} } = options;
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { apiUrl } = getConfig();
  const fetchUrl = useApiUrl ? `${apiUrl}${url}` : url;

  return async (data: unknown): Promise<T> => {
    try {
      const token = await getAccessTokenSilently();
      const config: RequestInit = deepMerge(
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: data instanceof FormData ? data : JSON.stringify(data),
        },
        { headers },
      );

      // this is so the browser can properly set the header with boundary length
      if (isMultiPartForm) {
        // @ts-ignore
        delete config.headers['Content-Type'];
      }

      return fetch(fetchUrl, config).then(async (resp) => {
        let body;
        if (resp.headers.get('content-type') === 'application/json') {
          body = await resp.json();
        } else {
          body = await resp.text();
        }

        if (resp.status !== 200 && resp.status !== 204 && resp.status !== 201) {
          throw new Error(getErrorMessage(body));
        }

        return body;
      });
    } catch {
      await loginWithRedirect(loginConfig(window.location.pathname));
      throw new Error('Not logged in. Redirecting...');
    }
  };
};
