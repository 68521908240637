import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputAdornment, Stack, SxProps, TextField, Typography, useTheme } from '@mui/material';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { MaterialReactTable, MaterialReactTableProps, MRT_ColumnDef, MRT_PaginationState, MRT_SortingState } from 'material-react-table';
import React, { ReactNode, useState } from 'react';
import { PaginatedEnvelope } from 'types/paginatedEnvelope';
import { useDebounce } from 'use-debounce';

interface DataTableProps<T extends Record<string, any>> {
  tableKey: string;
  tableHeading?: string;
  getTableData: (params: URLSearchParams) => Promise<PaginatedEnvelope<T>>;
  columnDefinitions: MRT_ColumnDef<T>[];
  searchPlaceholder: string;
  rowActions?: MaterialReactTableProps<T>['renderRowActionMenuItems'];
  newButton: ReactNode;
  sx?: SxProps;
}

const DataTableInner = <T extends Record<string, any>>({
  getTableData,
  columnDefinitions,
  tableKey,
  searchPlaceholder,
  newButton,
  tableHeading,
  rowActions,
  sx,
}: DataTableProps<T>) => {
  const theme = useTheme();
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [debouncedSearchTerm] = useDebounce<string>(globalFilter, 400);
  const { data, isError, isFetching, isLoading } = useQuery({
    queryKey: [tableKey, undefined, debouncedSearchTerm, pagination.pageIndex, pagination.pageSize, sorting],
    queryFn: async () => {
      const searchParams = new URLSearchParams();
      searchParams.set('page', `${pagination.pageIndex + 1}`);
      searchParams.set('size', `${pagination.pageSize}`);
      searchParams.set('search', debouncedSearchTerm ?? '');

      return getTableData(searchParams);
    },
    keepPreviousData: true,
  });

  return (
    <Stack sx={sx ?? {}}>
      <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          {tableHeading ? (
            <Typography display="inline" variant="h6" fontWeight="800">
              {tableHeading}
            </Typography>
          ) : (
            <></>
          )}
          <TextField
            sx={{ minWidth: '30%' }}
            size="small"
            placeholder={searchPlaceholder}
            variant="outlined"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton sx={{ visibility: globalFilter ? 'visible' : 'hidden' }} onClick={() => setGlobalFilter('')}>
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Stack>
      </Stack>
      <MaterialReactTable
        columns={columnDefinitions}
        data={data?.data ?? []}
        enableColumnFilters={false}
        manualPagination
        muiSearchTextFieldProps={{
          variant: 'outlined',
          size: 'small',
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [10, 20, 100],
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }
        muiTableHeadRowProps={{ sx: { backgroundColor: `${theme.palette.grey[100]}` } }}
        muiTableBodyRowProps={{
          sx: {
            minHeight: 65,
          },
        }}
        muiTableBodyCellProps={{
          sx: {
            py: 0.5,
          },
        }}
        muiBottomToolbarProps={{
          sx: {
            backdropFilter: 'blur(5px)',
            background: 'rgba(255, 255, 255, 0.60)',
          },
        }}
        muiTablePaperProps={{
          sx: {
            backgroundColor: 'transparent',
            height: '100%',
            maxHeight: '100%',
          },
        }}
        muiTableProps={{
          sx: {
            tableLayout: 'fixed',
          },
        }}
        muiTableContainerProps={{ sx: { maxHeight: 'calc(100vh - 400px)' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            header: '', //change header text
            size: 10, //make actions column wider
            maxSize: 10,
            muiTableBodyCellProps: {
              align: 'right',
            },
          },
        }}
        layoutMode="grid"
        enableRowActions
        renderRowActionMenuItems={rowActions}
        positionActionsColumn="last"
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        rowCount={data?.meta?.total ?? 0}
        enableTopToolbar={false}
        enableColumnActions={false}
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          sorting,
        }}
      />
      <Box sx={{ mt: 2 }}> {newButton}</Box>
    </Stack>
  );
};

const queryClient = new QueryClient();

export const DataTable = <T extends Record<string, any>>(props: DataTableProps<T>) => (
  <QueryClientProvider client={queryClient}>
    <DataTableInner {...props} />
  </QueryClientProvider>
);
