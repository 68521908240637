import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { AppBar, Box, Link, Popover, Stack, SxProps, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import ButtonBase from '@mui/material/ButtonBase';
import EducatiusLogo from 'assets/images/logo-educatius-header.svg';
import { UserMenuIconButton } from 'components/UserMenuIconButton';
import { useState } from 'react';
import { useMatch } from 'react-router-dom';
import { getConfig } from 'utils/config';

import { EducatiusProductsNav } from './EducatiusProductsNav';

export const EducatiusHeader = () => {
  const theme = useTheme();

  const { educatiusResourcesBasePath, marketplaceUrl } = getConfig();
  const educatiusNewsPath = `${educatiusResourcesBasePath}/news`;
  const educatiusHelpPath = `${educatiusResourcesBasePath}/resources/#GlobalTeam`;

  const isSharedView = useMatch('/marketing/profiles/:profileId/shared-view/p/:region/u/:userId/vp/:viewPricing');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const background = '#2E1A45';
  const hoverBgColor = '#FF500044';
  const activeBgColor = '#FE5000';
  const activeBorderBottom = `5px solid ${activeBgColor}`;

  const linkHoverSx: SxProps<Theme> = {
    bgcolor: hoverBgColor,
    transition: 'all 0.3s ease',
  };

  const linkBoxSx: SxProps<Theme> = {
    cursor: 'pointer',
    textDecoration: 'none',
    height: '90px',
    borderBottom: `5px solid ${background}`, // Holds space in dom while inactive
    '&:hover': {
      ...linkHoverSx,
      borderBottom: activeBorderBottom,
    },
    mx: 2,
    px: 2,
  };
  const activeLinkBoxSx: SxProps<Theme> = {
    ...linkBoxSx,
    bgcolor: hoverBgColor,
    borderBottom: activeBorderBottom,
  };
  const iconSx: SxProps<Theme> = {
    width: '24px',
    height: '24px',
    fontSize: '24px',
    color: theme.palette.primary.contrastText,
  };
  const iconLinkSx: SxProps<Theme> = {
    '&:hover': linkHoverSx,
    borderRadius: '0.5rem',
    fontSize: '24px',
    height: '40px',
    textDecoration: 'none',
    justifyItems: 'center',
    alignItems: 'center',
    display: 'flex',
    p: '8px',
  };
  const userMenuButtonSx: SxProps<Theme> = {
    '&:hover': linkHoverSx,
    borderRadius: '0.5rem',
    width: '24px',
    height: '24px',
    p: '20px',
  };

  return (
    <AppBar sx={{ zIndex: 100, boxShadow: 0, border: 0 }}>
      <Box
        sx={{
          pr: '30px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          background,
        }}
      >
        <ButtonBase>
          <Link href="/" sx={{ height: 30, m: '30px' }}>
            <img src={EducatiusLogo} alt="Educatius logo" height="30px" />
          </Link>
        </ButtonBase>

        {!isSharedView && (
          <>
            <Box flex={1}></Box>
            <ButtonBase sx={{ ...linkBoxSx, borderBottom: activeBorderBottom }} href={marketplaceUrl} data-intercom-target="browse schools">
              <Stack height="90px" direction="column" justifyContent="center">
                <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                  Browse schools
                </Typography>
              </Stack>
            </ButtonBase>
            <ButtonBase sx={anchorEl ? activeLinkBoxSx : linkBoxSx} onClick={(e) => setAnchorEl(e.currentTarget)} data-intercom-target="browse products">
              <Stack height="90px" direction="column" justifyContent="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                    Browse products
                  </Typography>
                  <ExpandMoreIcon />
                </Stack>
              </Stack>
            </ButtonBase>
            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <EducatiusProductsNav />
            </Popover>
            <ButtonBase data-intercom-target="resources button">
              <Link sx={linkBoxSx} href={educatiusResourcesBasePath}>
                <Stack height="90px" direction="column" justifyContent="center">
                  <Typography fontWeight="700" color={theme.palette.primary.contrastText}>
                    Resources
                  </Typography>
                </Stack>
              </Link>
            </ButtonBase>
            <Stack direction="row" ml={1.8} spacing={0.9} alignItems="center">
              <Tooltip title="News" arrow>
                <Link href={educatiusNewsPath} target="_blank" sx={iconLinkSx} data-intercom-target="news button">
                  <ArticleOutlinedIcon sx={iconSx} />
                </Link>
              </Tooltip>
              <Tooltip title="Help center" arrow>
                <Link href={educatiusHelpPath} referrerPolicy="no-referrer" sx={iconLinkSx} data-intercom-target="news button">
                  <HelpOutlineOutlinedIcon sx={iconSx} />
                </Link>
              </Tooltip>
              {/* The tooltip should sit beneath the use meny dropdown menu which has zIndex 1200 */}
              <Tooltip title="User menu" arrow PopperProps={{ style: { zIndex: 1000 } }}>
                <div>
                  <UserMenuIconButton sx={userMenuButtonSx} activeSx={{ bgcolor: activeBgColor }}>
                    <AccountCircleOutlinedIcon sx={iconSx} />
                  </UserMenuIconButton>
                </div>
              </Tooltip>
            </Stack>
          </>
        )}
      </Box>
    </AppBar>
  );
};
