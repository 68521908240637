import BgEducatiusWaves from 'assets/images/bg-educatius-waves.svg';
import { Branding } from 'branding/Branding';

import { EducatiusHeader } from './EducatiusHeader';
import { EducatiusFooter } from './EducatiusFooter';

export const educatiusBranding: Branding = {
  header: <EducatiusHeader />,
  headerHeight: 11,
  footer: <EducatiusFooter />,
  fontFamily: 'TheFuture, sans-serif',
  paletteOptions: {
    primary: {
      main: '#3F2776',
      // TODO: proper light variant?
      light: '#3F277622',
      dark: '#2E1A45',
      contrastText: '#ffffff',
    },
    titleGradientColor1: '#069491',
    titleGradientColor2: '#072B31',
  },
  backgroundSx: {
    backgroundImage: `url(${BgEducatiusWaves})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '45vw',
    backgroundPosition: 'bottom right',
    backgroundAttachment: 'fixed',
  },
};
