import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// TODO: Just use <CircularProgress /> directly...

export const Loader = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
    <CircularProgress />
  </Box>
);
