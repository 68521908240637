import React, { useEffect } from 'react';

interface RedirectExternalProps {
  url: string;
}

export const RedirectExternal = ({ url }: RedirectExternalProps) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <p>Redirecting...</p>;
};
