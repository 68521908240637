import { createTheme } from '@mui/material';
import { green, grey, orange, red } from '@mui/material/colors';
import { branding } from 'branding';

/**
 * If adding new mixins or variables to this file remember to update theme.d.ts definitions too
 */
const inputBorderColour = 'rgba(0, 0, 0, 0.23)';

// theme is split into two parts to allow second part to reference variables declared in first
const baseTheme = createTheme({
  typography: {
    fontFamily: branding.fontFamily,
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          borderRadius: '6px',
          border: '1px solid #D0D5DD',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #D0D5DD',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #D0D5DD',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#3EAD47',
      light: '#C5E6C8',
      dark: '#039855',
      contrastText: '#ffffff',
    },
    secondary: {
      main: grey[600],
      light: grey[300],
      dark: grey[700],
    },
    text: {
      primary: grey[800],
    },
    background: {
      default: '#ffffff',
    },
    success: {
      main: green[500],
      light: green[50],
      dark: green[700],
    },
    warning: {
      main: orange[500],
      light: orange[50],
      dark: orange[700],
    },
    error: {
      main: red[500],
      light: red[50],
      dark: red[700],
    },
    info: {
      main: grey[600],
      light: grey[200],
      dark: grey[700],
    },
    ...branding.paletteOptions,
  },
});

export const theme = createTheme(baseTheme, {
  mixins: {
    titleGradient: {
      background: `-webkit-linear-gradient(left, ${baseTheme.palette.titleGradientColor1}, ${baseTheme.palette.titleGradientColor2})`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    modalStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      maxHeight: '75vh',
      display: 'flex',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius: '10px',
      border: 'none',
      outline: 'none',
      p: 3,
    },
    secondaryText: {
      color: baseTheme.palette.text.primary,
      fontWeight: 'light',
      fontSize: 14,
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        sizeSmall: {
          borderRadius: '6px',
          border: `1px solid ${inputBorderColour}`,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: { color: `${baseTheme.palette.error.main}` },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
