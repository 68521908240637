import { Loader } from 'components/Loader';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthUser } from 'types/user';

import { loginConfig } from './login-config';

interface RequireAuthForRouteProps {
  children: ReactElement;
}

export const RequireAuthForRoute = ({ children }: RequireAuthForRouteProps): ReactElement => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0<AuthUser>();
  const [hasToken, setHasToken] = useState(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          setHasToken(true);
        }
      } catch {
        loginWithRedirect(loginConfig(window.location.pathname));
      }
    };

    if (!isLoading) {
      if (isAuthenticated) {
        fetchToken();
      } else {
        loginWithRedirect(loginConfig(window.location.pathname));
      }
    }
  }, [getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading]);

  if (isLoading || !hasToken) {
    return <Loader />;
  }
  if (isAuthenticated && user) {
    const isAgencyAdmin = user.userData.roles.find((r) => r === 'agent-agency-admin');
    if (isAgencyAdmin) {
      return children;
    } else {
      return <Navigate to="/marketing" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};
