import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Box, Link, Stack, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import Logo from 'assets/images/logo-educatius-footer.svg';
import { AuthUser } from 'types/user';

export const EnrollerFooter = () => {
  const theme = useTheme();

  const { user } = useAuth0<AuthUser>();
  const isEducatiusAgent = user?.userData.contractedWithEducatius;
  const linkSx = {
    textDecoration: 'none',
    color: theme.palette.primary.dark,
  };
  const year = new Date().getFullYear();

  return (
    <AppBar
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        p: 2,
        backgroundColor: '#fff',
        width: '100%',
        borderTop: `solid 1px ${grey[200]}`,
        top: 'auto',
        bottom: 0,
        zIndex: 1250,
      }}
      component="footer"
      position="relative"
      elevation={0}
    >
      {/* TODO: Confirm whether this needs to be here? */}
      {/* Will all educatius agents theoretically be using the educatius branding deployments? */}
      {isEducatiusAgent ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={Logo} alt="Educatius" height="26px" />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body2" sx={{ ml: 2, color: grey[800], fontWeight: 500 }}>
            &copy;{year} Enroller Ltd. All rights reserved
          </Typography>
        </Box>
      )}

      <Stack>
        <Box sx={{ display: 'flex', mr: 7 }}>
          <Link href="//help.enroller.co.nz" target="_blank" rel="noopener noreferrer" sx={linkSx}>
            <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
              Help Centre
            </Typography>
          </Link>
          <Link href="//www.enroller.co.nz/pages/terms-of-use" target="_blank" rel="noopener noreferrer" sx={{ ...linkSx, ml: 3 }}>
            <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
              Terms of Use
            </Typography>
          </Link>
          <Link href="//www.enroller.co.nz/pages/privacy-policy" target="_blank" rel="noopener noreferrer" sx={{ ...linkSx, ml: 3 }}>
            <Typography variant="body2" fontWeight="500" color={theme.palette.primary.dark}>
              Privacy Policy
            </Typography>
          </Link>
        </Box>
        {isEducatiusAgent && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', mr: 7, mt: 1 }}>
            <Typography variant="body2" sx={{ ml: 2, color: grey[800], fontWeight: 500 }}>
              &copy;{year} Enroller Ltd. All rights reserved
            </Typography>
          </Box>
        )}
      </Stack>
    </AppBar>
  );
};
