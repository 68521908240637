import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import CompareArrowIcon from '@mui/icons-material/CompareArrows';
import FlightTakeoffOutlinedIcon from '@mui/icons-material/FlightTakeoffOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { Box, Divider, Link, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';
import ImageProductsClassic from 'assets/images/educatius-header-products-classic.png';
import ImageProductsDual from 'assets/images/educatius-header-products-dual.png';
import ImageProductsOther from 'assets/images/educatius-header-products-other.png';
import { ImageWithSkeleton } from 'components/ImageWithSkeleton';
import { getConfig } from 'utils/config';

export const EducatiusProductsNav = () => {
  const theme = useTheme();

  const { educatiusResourcesBasePath } = getConfig();

  const linkSx: SxProps<Theme> = {
    fontSize: 13,
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  };

  return (
    <Box px={1} pb={1}>
      <Stack direction="row" mt={1} divider={<Divider variant="middle" sx={{ my: 2, mx: 1, width: '1px', bgcolor: 'divider' }} />}>
        <Stack direction="column" spacing={0.7} p={2}>
          <ImageWithSkeleton src={ImageProductsClassic} alt="Student with host parents" width="240px" height="120px" />
          <Typography fontWeight={700} py={1} sx={theme.mixins.titleGradient}>
            Destinations
          </Typography>
          <Link href={`${educatiusResourcesBasePath}/usa`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <img src="/flag-icons/USA.svg" alt="USA flag" width="16px" height="16px" />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                USA
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/uk`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <img src="/flag-icons/GBR.svg" alt="UK flag" width="16px" height="16px" />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                UK
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/ireland`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <img src="/flag-icons/IRL.svg" alt="Irish flag" width="16px" height="16px" />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Ireland
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/europe`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <img src="/flag-icons/EUR.svg" alt="EU flag" width="16px" height="16px" />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Europe
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/canada`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <img src="/flag-icons/CAN.svg" alt="Canadian flag" width="16px" height="16px" />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Canada
              </Typography>
            </Stack>
          </Link>
        </Stack>
        <Stack direction="column" spacing={0.7} p={2}>
          <ImageWithSkeleton src={ImageProductsDual} alt="Student in graduation gown" width="240px" height="120px" />
          <Typography fontWeight={700} py={1} sx={theme.mixins.titleGradient}>
            Dual Diploma
          </Typography>
          <Link href={`${educatiusResourcesBasePath}/hybrid-diploma`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <SchoolOutlinedIcon sx={{ width: '16px', height: '16px' }} />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Hybrid Diploma
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/study-online`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <PublicOutlinedIcon sx={{ width: '16px', height: '16px' }} />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Study Online
              </Typography>
            </Stack>
          </Link>
        </Stack>
        <Stack direction="column" spacing={0.7} p={2}>
          <ImageWithSkeleton src={ImageProductsOther} alt="San Francisco in the evening" width="240px" height="120px" />
          <Typography fontWeight={700} py={1} sx={theme.mixins.titleGradient}>
            Other Products
          </Typography>
          <Link href={`${educatiusResourcesBasePath}/edventours`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <FlightTakeoffOutlinedIcon sx={{ width: '16px', height: '16px' }} />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Edventours
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/career-development-internships`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <ScienceOutlinedIcon sx={{ width: '16px', height: '16px' }} />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Career Development &amp; Internships
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/language-preparation-courses`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <FlagOutlinedIcon sx={{ width: '16px', height: '16px' }} />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                Language Preparation Courses
              </Typography>
            </Stack>
          </Link>
          <Link href={`${educatiusResourcesBasePath}/j-1-classic-cultural-exchange-program/`} sx={linkSx}>
            <Stack direction="row" alignItems="center">
              <CompareArrowIcon sx={{ width: '16px', height: '16px' }} />
              <Typography variant="subtitle2" fontWeight="400" ml={0.6}>
                J-1 Classic Cultural Exchange Program
              </Typography>
            </Stack>
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
};
