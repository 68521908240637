import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { branding } from 'branding';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getConfig } from 'utils/config';
import '../assets/fonts/Inter-Medium.ttf';
import '../assets/fonts/Inter-Regular.ttf';
import '../assets/fonts/Inter-SemiBold.ttf';

import './App.scss';
import { Auth0ProviderWithNavigate } from './auth/Auth0ProviderWithNavigate';
import { CallbackLanding } from './auth/CallbackLanding';
import { Login } from './auth/Login';
import { RequireAuthForRoute } from './auth/RequireAuthForRoute';
import { PageTitleProvider } from './PageTitleContext';
import { RedirectExternal } from './RedirectExternal';
import { StaffOverview } from './staff/StaffOverview';
import { theme } from './theme';
import { Tracking } from './Tracking';
import { VersionCheckerModal } from './VersionCheckerModal';

export const App = () => {
  const { marketplaceUrl } = getConfig();

  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box component="main" display="flex" flexDirection="column" minHeight="100vh" height="100vh" overflow={'auto'} sx={branding.backgroundSx}>
          <BrowserRouter>
            <PageTitleProvider>
              <Auth0ProviderWithNavigate>
                <Tracking />
                <Header />
                <Box flex={1} mt={branding.headerHeight} height={`calc(100% - ${theme.spacing(branding.headerHeight)})`}>
                  <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="callback" element={<CallbackLanding />} />
                    <Route
                      path="staff"
                      element={
                        <RequireAuthForRoute>
                          <StaffOverview />
                        </RequireAuthForRoute>
                      }
                    />
                    <Route path="/marketing" element={<RedirectExternal url={`${marketplaceUrl}/login`} />} />
                    <Route path="*" element={<Navigate replace to="/staff" />} />
                  </Routes>
                </Box>
                <Footer />
                <VersionCheckerModal />
              </Auth0ProviderWithNavigate>
            </PageTitleProvider>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </SnackbarProvider>
  );
};
